import React from 'react'
import { Link } from 'gatsby'
import Logo from '../../atoms/Logo'
import { useSiteMetadata } from '../../../hooks/useSiteMetadata'
import styles from './Address.module.css'
import { useConsent } from '../../../hooks/useConsent'

export default function Address() {
  const { company } = useSiteMetadata()
  const year = new Date().getFullYear()

  const { resetConsentStatus } = useConsent()

  return (
    <div className={styles.address}>
      <div>
        <Link to="/" className={styles.logo} title="Back to Homepage">
          <Logo />
        </Link>

        <p>
          <strong>{company.name}</strong>
          <br />
          {company.address.location}
          <br />
          {company.address.street}
          <br />
          {company.address.zip} {company.address.city}
        </p>
      </div>

      <div className={styles.copyright}>
        <p>
          <small>
            © {year} {company.name} — All Rights Reserved
          </small>
        </p>
        <p>
          <Link to="/privacy">Privacy Policy</Link>
          <button className={styles.cookieButton} onClick={resetConsentStatus}>
            Cookie settings
          </button>
        </p>
      </div>
    </div>
  )
}
