import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import MenuLink, { MenuItem } from '../../molecules/Menu/MenuLink'
import Follow from './Follow'
import styles from './Menu.module.css'

export interface MenuFooterItem {
  name: string
  items: MenuItem[]
}

const query = graphql`
  query FooterQuery {
    allMenuFooterJson {
      edges {
        node {
          name
          items {
            name
            url
            badge
          }
        }
      }
    }

    allMenuJson {
      edges {
        node {
          name
          submenu {
            name
            url
            badge
          }
        }
      }
    }
  }
`

const MenuMain = ({ data }: { data: any }) => {
  const menu = data.allMenuFooterJson.edges
  const menuMain = data.allMenuJson.edges

  return (
    <div className={styles.menu}>
      {menu.map(({ node }: { node: MenuFooterItem }) => {
        // auto-populate Technology (Learn == [0]) items
        const items = node.items || menuMain[0].node.submenu

        return (
          <div key={node.name}>
            <h3 className={styles.title}>{node.name}</h3>
            <ul>
              {items.map((item: MenuItem) => (
                <li key={item.name}>
                  <MenuLink className={styles.link} item={item} />
                </li>
              ))}
            </ul>
          </div>
        )
      })}
    </div>
  )
}

export default function MenuFooter() {
  const data = useStaticQuery(query)
  const menuMain = data.allMenuJson.edges

  // assumes far right menu contains "more/other"
  const menuMainMore = menuMain[menuMain.length - 1].node

  return (
    <>
      <MenuMain data={data} />

      <div className={styles.menuSecondary}>
        <h3 className={styles.title}>More</h3>
        <ul>
          {/* auto-populate More items */}
          {menuMainMore.submenu.map((item: MenuItem) => (
            <li key={item.name}>
              <MenuLink className={styles.link} item={item} />
            </li>
          ))}
        </ul>
      </div>

      <Follow />
    </>
  )
}
