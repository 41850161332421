import React from 'react'
import MenuLink, { MenuItem } from './MenuLink'
import styles from './SubMenu.module.css'

export default function SubMenu({ submenu }: { submenu: MenuItem[] }) {
  return (
    <ul className={styles.submenu}>
      {submenu.map((subitem: MenuItem) => (
        <li key={subitem.name}>
          <MenuLink item={subitem} />
        </li>
      ))}
    </ul>
  )
}
