import React, { CSSProperties, ReactNode } from 'react'
import classNames from 'classnames/bind'
import { useTrail, animated } from 'react-spring'
import Container from '../atoms/Container'
import ButtonActions, { ButtonActionItem } from '../molecules/ButtonActions'
import Menu from '../molecules/Menu'
import { markdownify } from '../../utils'
import Waves from '../atoms/Waves'
import styles from './Hero.module.css'
import usePrefersReducedMotion from '../../hooks/usePrefersReducedMotion'
import { ReactComponent as Arrow } from '../../images/arrow.svg'

export interface HeroProps {
  title: string
  description?: string
  center?: boolean
  content?: ReactNode
  small?: boolean
  dark?: boolean
  actions?: ButtonActionItem[]
  style?: CSSProperties
  label?: ReactNode
  uniformActionButtons?: boolean
  notification?: { title: string | ReactNode; url: string }
  noWaves?: boolean
}

const cx = classNames.bind(styles)

const animation = {
  config: { tension: 300, friction: 25 },
  from: { transform: 'translate3d(0, 3rem, 0)', opacity: 0.00001 },
  to: { transform: 'translate3d(0, 0, 0)', opacity: 1 }
}

export default function Hero({
  title,
  description,
  center,
  content,
  small,
  actions,
  style,
  dark,
  label,
  uniformActionButtons,
  notification,
  noWaves
}: HeroProps) {
  const styleClasses = cx({
    hero: true,
    center: center,
    small: small,
    dark: dark
  })
  const prefersReducedMotion = usePrefersReducedMotion()

  const animatedItems = [
    label && <span className={styles.label}>{label}</span>,
    notification && (
      <a href={notification.url} className={styles.notification}>
        {notification.title} <Arrow />
      </a>
    ),
    title && <h1 className={styles.title}>{title}</h1>,
    description && (
      <div className={styles.description}>{markdownify(description)}</div>
    ),
    actions && (
      <ButtonActions
        className={styles.actions}
        actions={actions}
        uniformButtons={uniformActionButtons}
      />
    )
  ]

  const trail = useTrail(animatedItems.length, {
    ...animation,
    immediate: prefersReducedMotion
  })

  return (
    <header className={styleClasses} style={style}>
      <Menu />
      <Container>
        <hgroup>
          {trail.map(
            (props, index) =>
              animatedItems[index] && (
                <animated.div key={index} style={props}>
                  {animatedItems[index]}
                </animated.div>
              )
          )}
        </hgroup>

        {content && <div className={styles.content}>{content}</div>}
      </Container>
      {!noWaves && <Waves />}
    </header>
  )
}
