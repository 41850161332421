import React from 'react'
import Button from '../atoms/Button'
import styles from './ButtonActions.module.css'

export interface ButtonActionItem {
  title: string
  url: string
}

export default function ButtonActions({
  actions,
  className,
  uniformButtons
}: {
  actions: ButtonActionItem[]
  className?: string
  uniformButtons?: boolean
}) {
  return (
    <div className={`${styles.actions} ${className && className}`}>
      {actions.map((action: ButtonActionItem, index: number) => {
        const isUrl =
          action.url &&
          (action.url.includes('http') || action.url.includes('#'))

        return (
          <Button
            style={
              uniformButtons ? 'primary' : index === 0 ? 'primary' : 'ghost'
            }
            href={isUrl && action.url}
            to={!isUrl && action.url}
            key={action.title}
          >
            {action.title}
          </Button>
        )
      })}
    </div>
  )
}
