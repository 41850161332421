import React from 'react'
import loadable from '@loadable/component'
import { useSpring, animated } from 'react-spring'
import SubMenu from './SubMenu'
import MenuLink, { MenuItem } from './MenuLink'

const Tippy = loadable(() => import('@tippyjs/react/headless'))

const animation = {
  config: { tension: 400, friction: 20 },
  from: { transform: 'scale(0.5) translateY(-3rem)' },
  to: { transform: 'scale(1) translateY(0)' }
}

export default function MenuLinkTippy({ item }: { item: MenuItem }) {
  const [props, setSpring] = useSpring(() => animation.from)

  function onMount() {
    setSpring({
      transform: 'scale(1) translateY(0)',
      onRest: () => null,
      config: animation.config
    })
  }

  function onHide({ unmount }: { unmount: any }) {
    setSpring({
      ...animation.from,
      onRest: unmount,
      config: { ...animation.config, clamp: true }
    })
  }

  return (
    <Tippy
      interactive
      interactiveBorder={30}
      render={(attrs) => (
        <animated.div style={props}>
          <SubMenu submenu={item.submenu} {...attrs} />
        </animated.div>
      )}
      appendTo={
        typeof document !== 'undefined' && document.querySelector('body')
      }
      animation
      onMount={onMount}
      onHide={onHide}
      fallback={<MenuLink item={item} />}
    >
      <MenuLink item={item} />
    </Tippy>
  )
}
