/*
    Determine if the given rule is a CSSStyleRule
    See: https://developer.mozilla.org/en-US/docs/Web/API/CSSRule#Type_constants
*/
export const isStyleRule = (rule: CSSStyleRule) => rule.type === 1

/**
 * Get all custom properties on a page
 * e.g. [["--color-accent", "#b9f500"], ["--color-text", "#252525"], ...]
 */
export function getCSSCustomProps(): [string, string][] {
  // provide some real fallback for tests
  if (typeof document === 'undefined' || process.env.NODE_ENV === 'test')
    return [['--brand-pink', '#ff4092']]

  // styleSheets is array-like, so we convert it to an array.
  return [...(document.styleSheets as any)].reduce(
    (finalArr: string[], sheet) =>
      finalArr.concat(
        // cssRules is array-like, so we convert it to an array
        [...sheet.cssRules].filter(isStyleRule).reduce((propValArr, rule) => {
          const props = [...rule.style]
            .map((propName: string) => [
              propName.trim(),
              rule.style.getPropertyValue(propName).trim()
            ])
            // Discard any props that don't start with "--"
            .filter(([propName]) => propName.indexOf('--') === 0)

          return [...propValArr, ...props]
        }, [])
      ),
    []
  )
}
