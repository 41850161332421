import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Logo from '../../atoms/Logo'
import Container from '../../atoms/Container'
import MenuLink, { MenuItem } from './MenuLink'
import MenuLinkTippy from './MenuLinkTippy'
import styles from './index.module.css'

const query = graphql`
  query MenuQuery {
    allMenuJson {
      edges {
        node {
          name
          submenu {
            name
            url
            badge
          }
        }
      }
    }
  }
`

export default function Menu() {
  const data = useStaticQuery(query)
  const menu = data.allMenuJson.edges

  return (
    <nav className={styles.menu}>
      <Container>
        <Link to="/" className={styles.logo} title="Back to Homepage">
          <Logo />
        </Link>

        <ul>
          {menu.map(({ node }: { node: MenuItem }) => (
            <li key={node.name}>
              {node.submenu ? (
                <MenuLinkTippy item={node} />
              ) : (
                <MenuLink item={node} />
              )}
            </li>
          ))}
        </ul>
      </Container>
    </nav>
  )
}
