import React from 'react'
import { useSiteMetadata } from '../../../hooks/useSiteMetadata'
import FormNewsletter from '../../pages/newsletter/FormNewsletter'
import { SocialIconLink } from '../../atoms/SocialIcon'
import styles from './Follow.module.css'

export default function Follow() {
  const { social } = useSiteMetadata()

  return (
    <div className={styles.follow}>
      <div className={styles.social}>
        <h3 className={styles.title}>Follow</h3>
        {social.map(({ name, url }: { name: string; url: string }) => (
          <SocialIconLink
            key={name}
            url={url}
            title={name}
            icon={name.toLowerCase()}
          />
        ))}
      </div>
      <div className={styles.newsletter}>
        <h3 className={styles.title}>Newsletter</h3>
        <FormNewsletter />
      </div>
    </div>
  )
}
