import React from 'react'
import { Link } from 'gatsby'
import { ReactComponent as Caret } from '../../../images/caret.svg'
import { ReactComponent as External } from '../../../images/external.svg'
import Badge from '../../atoms/Badge'
import styles from './MenuLink.module.css'

export interface MenuItem {
  name: string
  url?: string
  submenu?: MenuItem[]
  badge?: string
}

const MenuLinkContent = ({ item }: { item: MenuItem }) => (
  <>
    {item.name}
    {item.submenu && <Caret />}
    {item.badge && <Badge className={styles.badge} label={item.badge} />}
  </>
)

// Forward ref for Tippy.js
// eslint-disable-next-line
const MenuLink = React.forwardRef(
  ({ item, className }: { item: MenuItem; className?: string }, ref: any) =>
    item.url && item.url.includes('http') ? (
      <a
        className={`${styles.link} ${className && className}`}
        href={item.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <MenuLinkContent item={item} />
        <External />
      </a>
    ) : item.url ? (
      <Link
        className={`${styles.link} ${className && className}`}
        to={item.url}
        ref={ref}
      >
        <MenuLinkContent item={item} />
      </Link>
    ) : (
      <button className={styles.more} ref={ref}>
        <MenuLinkContent item={item} />
      </button>
    )
)

export default MenuLink
