import remark from 'remark'
import parse from 'remark-parse'
import remark2react from 'remark-react'
import breaks from 'remark-breaks'
import toc from 'remark-toc'

export function markdownify(text: string) {
  return (remark() as any)
    .use(parse)
    .use(toc, { maxDepth: 3, tight: true })
    .use(breaks)
    .use(remark2react, {
      sanitize: { clobberPrefix: '' } // needed to remove 'user-content' string from generated IDs
    })
    .processSync(text).result
}
