import React from 'react'

import { ReactComponent as ButtonTelegram } from '../../images/telegram.svg'
import { ReactComponent as ButtonTwitter } from '../../images/twitter.svg'
import { ReactComponent as ButtonMedium } from '../../images/medium.svg'
import { ReactComponent as ButtonGithub } from '../../images/github.svg'
import { ReactComponent as ButtonGitcoin } from '../../images/gitcoin.svg'
import { ReactComponent as ButtonLinkedin } from '../../images/linkedin.svg'
import { ReactComponent as ButtonYouTube } from '../../images/youtube.svg'
import { ReactComponent as ButtonDiscord } from '../../images/discord.svg'
import { ReactComponent as ButtonDocs } from '../../images/docs.svg'
import { ReactComponent as ButtonMeetup } from '../../images/meetup.svg'
import { ReactComponent as ButtonOcean } from '../../images/oceanSocial.svg'
import { ReactComponent as ButtonPodcast } from '../../images/podcast.svg'
import { ReactComponent as ButtonWebsite } from '../../images/website.svg'
import { ReactComponent as ButtonInstagram } from '../../images/instagram.svg'

const styles = {
  display: 'inline-block'
}

export function SocialIcon({ icon }: { icon: string }) {
  switch (icon) {
    case 'medium':
    case 'blog':
      return <ButtonMedium style={styles} />
    case 'twitter':
      return <ButtonTwitter style={styles} />
    case 'telegram':
      return <ButtonTelegram style={styles} />
    case 'github':
    case 'GitHub':
      return <ButtonGithub style={styles} />
    case 'gitcoin':
      return <ButtonGitcoin style={styles} />
    case 'linkedin':
      return <ButtonLinkedin style={styles} />
    case 'youtube':
    case 'YouTube':
      return <ButtonYouTube style={styles} />
    case 'discord':
    case 'Discord':
      return <ButtonDiscord style={styles} />
    case 'docs':
    case 'Docs':
    case 'Documentation':
      return <ButtonDocs style={styles} />
    case 'meetup':
    case 'Meetup':
      return <ButtonMeetup style={styles} />
    case 'instagram':
    case 'Instagram':
      return <ButtonInstagram style={styles} />
    case 'podcast':
    case 'Voices of Data Economy':
      return <ButtonPodcast style={styles} />
    case 'website':
    case 'Website':
      return <ButtonWebsite style={styles} />
    default:
      return <ButtonOcean style={styles} />
  }
}

export interface SocialIconProps {
  url: string
  icon: string
  title: string
}

export function SocialIconLink({ url, icon, title }: SocialIconProps) {
  return (
    <a href={url} title={title} rel="noopener noreferrer" target="_blank">
      <SocialIcon icon={icon} />
    </a>
  )
}
