import React from 'react'
import Container from '../../atoms/Container'
import Address from './Address'
import MenuFooter from './Menu'
import Waves from '../../atoms/Waves'
import styles from './index.module.css'

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <Container className={styles.grid}>
        <Address />
        <div>
          <MenuFooter />
        </div>
      </Container>
      <Waves className={styles.waves} />
    </footer>
  )
}
