import React from 'react'
import styles from './Badge.module.css'

export default function Badge({
  label,
  className
}: {
  label: string
  className?: string
}) {
  return <span className={`${styles.badge} ${className}`}>{label}</span>
}
