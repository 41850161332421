import axios from 'axios'

export async function sendFormData(values: {
  [key: string]: string
}): Promise<{ success: boolean }> {
  const response = await axios.post('/api/formData', values)

  if (response.status !== 200) return { success: false }
  return { success: true }
}
