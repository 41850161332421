import React, { ReactElement, ReactNode, CSSProperties } from 'react'
import Container from './atoms/Container'
import Hero from './organisms/Hero'
import Seo from './atoms/Seo'
import { ButtonActionItem } from './molecules/ButtonActions'
import Footer from './organisms/Footer'
import CookieBanner from './molecules/CookieBanner'
import ConsentProvider from '../hooks/useConsent'

export interface LayoutProps {
  title?: string
  description?: string
  heroCenter?: boolean
  heroSmall?: boolean
  heroActions?: ButtonActionItem[]
  heroContent?: ReactElement
  heroStyle?: CSSProperties
  heroDark?: boolean
  heroLabel?: ReactNode
  heroNotification?: { title: string | ReactNode; url: string }
  heroNoWaves?: boolean
  uniformHeroActionButtons?: boolean
  contentNarrow?: boolean
  children: ReactNode
  uri?: string
  location?: Location
}

export default function Layout({
  title,
  description,
  heroCenter,
  heroSmall,
  heroActions,
  heroContent,
  heroStyle,
  heroDark,
  heroLabel,
  heroNotification,
  heroNoWaves,
  contentNarrow,
  uniformHeroActionButtons,
  children,
  uri,
  location
}: LayoutProps): ReactElement {
  return (
    <>
      <Seo
        title={title}
        description={description}
        uri={uri}
        location={location}
      />
      <Hero
        title={title}
        description={description}
        center={heroCenter}
        small={heroSmall}
        actions={heroActions}
        uniformActionButtons={uniformHeroActionButtons}
        content={heroContent}
        style={heroStyle}
        dark={heroDark}
        label={heroLabel}
        notification={heroNotification}
        noWaves={heroNoWaves}
      />
      <ConsentProvider>
        <main id="document">
          <Container narrow={contentNarrow || null}>{children}</Container>
        </main>

        <Footer />
        <CookieBanner />
      </ConsentProvider>
    </>
  )
}
