import React from 'react'
import { markdownify } from '../../utils'
import styles from './Gdpr.module.css'
import { useGdprMetadata } from '../../hooks/useGdprMetadata'

export default function Gdpr({ text }: { text: string }) {
  const gdpr = useGdprMetadata()

  return <div className={styles.gdpr}>{markdownify(gdpr[text])}</div>
}
