import React, { useState, ChangeEvent } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import axios from 'axios'
import loadable from '@loadable/component'
import Input from '../../atoms/Input'
import Button from '../../atoms/Button'
import Gdpr from '../../atoms/Gdpr'
import styles from './FormNewsletter.module.css'

const Alert = loadable(() => import('../../atoms/Alert'))

const url = `/api/newsletter`

const query = graphql`
  query NewsletterQuery {
    newsletter: allFile(
      filter: { relativePath: { eq: "pages/newsletter.json" } }
    ) {
      edges {
        node {
          childPagesJson {
            form {
              placeholder
              button
              status {
                sending
                exists
                success
              }
              gdpr
            }
          }
        }
      }
    }
  }
`

interface Status {
  status: 'error' | 'success' | 'sending'
  message?: string
}

export default function FormNewsletter() {
  const data = useStaticQuery(query)
  const content = data.newsletter.edges[0].node.childPagesJson.form
  const { placeholder, button, status, gdpr } = content

  const [subscribeStatus, setSubscribeStatus] = useState<Status>()
  const [email, setEmail] = useState<string>()

  const isDisabled =
    !email ||
    email.length < 5 ||
    email.indexOf('@') === -1 ||
    (subscribeStatus && subscribeStatus.status === 'sending')

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    setSubscribeStatus(null)
    setEmail(e.target.value)
  }

  async function handleSubmit() {
    setSubscribeStatus({ status: 'sending', message: status.sending })
    try {
      const response = await axios.post(url, { email })

      response.data.success
        ? setSubscribeStatus({ status: 'success', message: status.success })
        : setSubscribeStatus({
            status: 'error',
            message: `Error: ${response.data.error}`
          })
    } catch (error) {
      console.error(error.message)
      setSubscribeStatus({
        status: 'error',
        message: `Error from API: ${error.message}`
      })
    }
  }

  return (
    <form action={url} method="post">
      <div className={styles.inputGroup}>
        <Input
          type="email"
          name="email"
          label="Your Email"
          placeholder={placeholder}
          required
          onChange={handleInputChange}
        />
        <Button type="submit" disabled={isDisabled} onClick={handleSubmit}>
          {button}
        </Button>
      </div>

      {subscribeStatus && (
        <Alert
          severity={
            subscribeStatus.status === 'sending'
              ? 'info'
              : subscribeStatus.status
          }
        >
          {subscribeStatus.message}
        </Alert>
      )}
      <Gdpr text={gdpr} />
    </form>
  )
}
